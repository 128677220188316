import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Details, Blockquote } from "../components/details.js";
import MoreProjects from "../components/moreProjects.js";
import kpi from "../images/kpi.png";
import initial from "../images/covid-main/initial.png";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <p>{`During my internship at the Ontario Digital Service as a product designer, the COVID-19 pandemic struck. My team was pulled in to tackle the Ontario government’s delivery of information regarding COVID-19. The final microsite received 1.25 million views in one week after launch and continues to receive over 450,000 views per month. Visit the live website at `}<a href="https://covid-19.ontario.ca" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca`}</a>{`.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADM0lEQVQ4yzWP309bZRyHe+PVXEyWGDNjbJcY5242SIzOCxO9IiYNkhIv3B/gjT+jidPswgydQXFD2USYiDClm14YjRGZBSpdKZRt0FHZ7I/T0xbannNaxrqWc07Pjz6mp3jx5H3fz/t+n3xel64soEpBqMcAAawk2Ok2tXWs6hp2Ndbmfgzq62CloCmAnWrPqHHH0XK5VGmeZvUGqzejjIx8w9TUj4yPf+ew8U+cfD6HKGbIZkUEIU08vs4PlyYZHR1lcnLCmVkMh6C2hlqaw1UvBsC4zeDgIA/uf4hjHZ243R7cbjder5fe3l66u7vx+Xz09PTQ1dXF4cNP8fhjB3nyiUPs27efDz485bStFwK4NGkW436cwso4q0Md3Bh+nusXniN07jgzZzqZ7jvKnx8fZeaTYw5/fdrJwhdPE/zax/TZl4iePUI+dA6z9i9aKYBLl2bR7m3QvH0edeIAP737CP53HiY3/CjmFQ+a30Pjsgf9sgdtyo3mP8TuxAHEn08QGHiB2sUHIHYarZpEl5yGc2h3VzGKEWrX+5m/0sesv4/K0mcYsQG01Tbqzc+x4l9ibwzR3PgKNTaEeus8xlo/en4OfWedlsulyUE0ZRHTBgvYLCoU5QqZrQoJUSadL5Mt7iBsblPVQLVg1wTdgoYNTcBsglaOosvBPWF5CaOhYVsGulqnoanOqql11HoN2zQAG9tsYBs6ptnANA2Hpm1iGg20ygq6PP+/MOKEpmVRlGWKssJWqUS5LFOUtri7s41p2xiW5WBbFlgGOEIb0zT3hHsNVSUCpo7UgBevChwZW+C1lU3+uHOK/qsvM534iIHlv/GNXOCtwAzKbxfJvO2l8H4v5O7Q6q8py7RcrlbNVkNsg+Jug86xWQ6evsSJ36NMBN/g9eFn+H7+TU7+4uf4yfd4dfxb1sb68Xs7+PWVZ1GTt7CBRmW5/WVVvka1FKVcriBJCplUmnQyhShkENI5EgkBQciREURSyTRCSkAQcyTTIom0yFZRQilvs1OIsiuFcNVKYcr5ReRsGCW7SCW/RDm/hJLby3IRZ9+6czIxjCSGkZ1zxHlTyoQoZRa4V7jGfxepXdYT6119AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header image of COVID-19 microsite",
            "title": "Header image of COVID-19 microsite",
            "src": "/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/1cfc2/header.png",
            "srcSet": ["/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/3684f/header.png 225w", "/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/fc2a6/header.png 450w", "/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/1cfc2/header.png 900w", "/static/1ddd4dbbdbc2d6dd2224d76096cbd2c6/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Context`}</h4>
    <h2>{`Problem`}</h2>
    <p>{`With the COVID-19 outbreak, there has been information overloading Ontarians from all directions. They have difficulty identifying the important information they should know. This was particularly true on the initial version of Ontario’s COVID-19 site:`}</p>
    <div className="row">
  <div className="column">
    <div className="list">
      <h3>1. The page was extremely long (much longer than this case study)</h3>
      <h3>
        2. It was densely packed with information; the entire page was one
        column with text and limited visual elements, making it difficult for
        Ontarians to find and understand the content they need
      </h3>
      <h3>
        3. The data presented had many technical nuances that confused people
      </h3>
    </div>
  </div>
  <div className="column column-img">
    <img src={initial} alt="The initial COVID-19 information page had many problems" />
    <p className="caption">
      The initial COVID-19 informational page was long, text-heavy, and
      difficult to understand. This was not even a tenth of the full page.
    </p>
  </div>
    </div>
    <p>{`As the Ontario government, we should ensure that Ontarians are easily finding the information they need during these unprecedented times. Our team recognized the importance of a user-friendly website for Ontarians and set out developing one.`}</p>
    <h2>{`Breaking down the goal`}</h2>
    <p>{`To guide our product, we created the following refined problem statement and overall goal:`}</p>
    <Blockquote text="How might we empower Ontarians with knowledge regarding COVID-19 by streamlining our information and resources in a digestible way?" mdxType="Blockquote" />
    <p>{`Due to the urgency of the situation in early March, we had three days to complete a pitchable MVP. By creating this prototype, we would accomplish:`}</p>
    <div className="list">
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>
      Design a page with important information that is understandable by
      Ontarians
    </h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>
      Convince executives of the need for an informative COVID-19 page for
      Ontarians
    </h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Create a first draft and roadmap for the team we hand off to</h3>
  </div>
    </div>
    <h2>{`Our approach`}</h2>
    <div className="row">
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">1.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Research</h3>
      <p>User interviews</p>
      <p>Personas</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">2.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Design</h3>
      <p>Information architecture</p>
      <p>Low-fi designs</p>
      <p>Hi-fi designs</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">3.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Evaluate</h3>
      <p>User testing</p>
      <p>Design iterations</p>
      <p>Reflection</p>
    </div>
  </div>
    </div>
    <p>{`The initial engagement for the project was 3 days in a team of 6 designers. Thus, we had to work quickly to understand our users and define a structure for the final site. After the prototype was approved by executives, I was on a team of 3 doing usability testing for the live website, reporting research insights, and assisting in the refinement of the visual design. `}<AnchorLink to="/covid-main#solution" title="Solution" className="link" mdxType="AnchorLink">{`
Jump to the final iteration I created during my internship.`}</AnchorLink></p>
    <h4>{`Research`}</h4>
    <h2>{`Understanding our audience`}</h2>
    <p>{`With over 14 million people living in Ontario, the population is bound to be diverse. To inform our designs, we reached out to various individuals around the province to understand what they are looking for during these unprecedented times. This included conducting user interviews with generative questions to analyze their most prominent concerns.`}</p>
    <p>{`Some of the questions we asked include, but are not limited to:`}</p>
    <Blockquote text={'"How do you feel about COVID-19?"'} mdxType="Blockquote" />
    <Blockquote text={'"What information related to COVID-19 do you seek out?"'} mdxType="Blockquote" />
    <Blockquote text={'"What COVID-19 information do you want the government to provide on their website?"'} mdxType="Blockquote" />
    <p>{`We consolidated this research into a series of user personas. It gave us an overview of the different people in Ontario and the information they may be looking for regarding COVID-19.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a358f9e6cf0dc9e186144c2e8b174185/e2d3b/personas.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQozyWSWY7sRBQFvf+dgNgDEg8JxHtAD9U1u2yn59lOp9NpV/cNVMVH6Or+HZ0Tngo/KNIj21xSZSfMmMESsuiIJC2xy4KZLZFSxEnBPCjccCDy36jSI/OYUCQHZp3zZQO8129/8PHtd+7A5eJTlB2rKdimG21TYk2H0S1VEdHUBaY9w2eLijKapmVxd4qyxm1wtwVeHmfUaYqeNMHNJ0lS3JSytC8M1YGh3DFWB0x7Yu59PvUepxV5UdF17ZPL+USWFaxTjNf0ButWlmVFqeSZcNEpbAXf/9rx8nJgtpbz5UKW18haMQ8hKs4xZmYyltstoOn0M4i3TgqnQ5wOuM+KzcS4fsfXknK9RqRpwed9I88z6rrFTQmuf8P0AcsY4B7oBxGu/8AL/QPqtkP3OSo40FQJssTYMSJOS+q6YdSG0/lKEGXMQ4ztz/jHf+mrEKMrVLBn6AruJsCLgpAk8lnW+3OUMMowQ8KnuTLrknnMMGNKlV0wY8E6XoCBP3/7Ff90ZN2+yLIcPTmcjvHSrJSua0XrUXz/KmmWy6JTcd1OqnQvQ3UWO0bSl0eZulDu00lMd5O8qGVZrIzjIGFwkzQrxE1KPGM3vkSYH65Fiqrp/x+Fnl9++pmXH99Z78L7bo9KSrY5f9bR9hoRno4qFVNWD90SPNd/iOvexTavsnTvsg57Wdsf8mkzCYJQyiIXa63keSZV3YrVsWz932Lbd7Htqyzt2/M+/rX7R/4DHgvq8IhWQbEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Personas for Ontarians",
            "title": "Personas for Ontarians",
            "src": "/static/a358f9e6cf0dc9e186144c2e8b174185/1cfc2/personas.png",
            "srcSet": ["/static/a358f9e6cf0dc9e186144c2e8b174185/3684f/personas.png 225w", "/static/a358f9e6cf0dc9e186144c2e8b174185/fc2a6/personas.png 450w", "/static/a358f9e6cf0dc9e186144c2e8b174185/1cfc2/personas.png 900w", "/static/a358f9e6cf0dc9e186144c2e8b174185/21482/personas.png 1350w", "/static/a358f9e6cf0dc9e186144c2e8b174185/e2d3b/personas.png 1466w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  Creating personas helped guide us in creating an informative site for all.{" "}
  <a href="https://www.figma.com/file/ZnuXm9aPWcvaeShnJxrTym/Ontario-COVID-19-Microsite-%E2%80%93-Personas?node-id=0%3A1" className="link" target="_blank" rel="noreferrer">
    See all the personas.
  </a>
    </p>
    <h4>{`Design`}</h4>
    <h2>{`Structuring the site`}</h2>
    <p>{`To define the information architecture of the site, I suggested a card sorting exercise. We took the contents of the current site, and split it into two piles: to include or not to include. This is because a lot of the esiting content was irrelevant to the typical Ontarian – either it was too technical, or heavily repeated material that made the page difficult to decipher. We also ordered the material in the "include" category so that the most important information is first.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/918d984951ce4ce3ae946ed4272d7d94/b3ad9/cards.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACS0lEQVQ4y32Sa28cNRSG9///Dpp84EOjVkUQqS0SNIvaySZpoajqXiCz2SR7mRnP+H57qtlZkgKCIz0+ln386rV9RshLUO//Rm4voLs8cEFuz0Fegf7wr9o98mo40xaMnBFIKZFdS9cJlJKQEzlHcp+TBz3D6hYhmqGuFXu0krSiwTkLye5FRzflnFcvX3H6w/ccHx9z9uZnhsjDmCLEktn0E988ecJ4PObs7Ixnz59xenrK0dER09lsXxubCSPnDdd1YNMFOhu5biLCZloHpchIl0BPCc7RNQFvE8FnRGVxNmJUQIpI9I7cXTLqrOXpuyXFomGj4WmxYrqxzLaOk8kdZWXBzVFC86Eo2d4p2tpRvJmiu8h61fHrZIlVhv4/Hh3KgPI8OBQmUYq0d907jN6ju0xwA73D3qnVkbYOjw611fx2a7muHcLC2+mW+9az1Zn3N5pKR7AztFTcli1SWIyK3N90++v+FTm54VNarXk+WVEsau5l5tvxgunasNh5TooVy9qBX1BtKsY/fmT5x4ZmZ7h6+yf1Tg9i+StB7w1rxf6a2iduaktrAr2xyoEJmaympOCxOuFdJCWIHvoGeOyGg6Cyhp8+Vfx+K9mqxOuPa64ry2ItefFuwXLbgZmTkuMx8gM5/0OwM4bvJiWTxY474Tn5Zc7n2475WvHiYkW5U2DnQ4P/T/T7ub1kFIPC+ogPAR882licc8SUiBliDCT5mRQMKSVSCqQUvyIM68Ec3rA+J9QT/IHQHObV+QOpKfbZVZP/pN/PouALR6vZQ/XvSBwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Card sorting",
            "title": "Card sorting",
            "src": "/static/918d984951ce4ce3ae946ed4272d7d94/1cfc2/cards.png",
            "srcSet": ["/static/918d984951ce4ce3ae946ed4272d7d94/3684f/cards.png 225w", "/static/918d984951ce4ce3ae946ed4272d7d94/fc2a6/cards.png 450w", "/static/918d984951ce4ce3ae946ed4272d7d94/1cfc2/cards.png 900w", "/static/918d984951ce4ce3ae946ed4272d7d94/b3ad9/cards.png 909w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We had a card sorting exercise to prioritize and structure information.
    </p>
    <p>{`This also helped us identify the content we were missing based on the findings from our user interviews. We ensured that we added these missing pieces to the structure of the website.`}</p>
    <p>{`We started prototyping in Balsamiq first to refine the overall structure. This is when we realized the breadth of information surpassed the comfortable confines of a single web page and warranted the need for something more.`}</p>
    <p>{`The government of Ontario has its dedicated site for all of Ontario's information. However, given the breadth and depth of information regarding COVID-19, this calls for a site on its own – a dedicated microsite for COVID-19 information. This separate site helps us cover the numerous pockets of information the different groups of Ontarians want to see as informed by our user research. Thus, `}<a href="https://covid-19.ontario.ca" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca`}</a>{` was born as the hub of all information related to COVID-19 for Ontario.`}</p>
    <p>{`As such, we pitched a microsite to executives. We prototyped high fidelity mockups of the MVP in Figma. These pages were very text heavy as to ensure all the information deemed important by executives was there to help secure approval for production. You can see the pages we pitched below and see how much the current site has changed since conception!`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FcRsZrZgPC7CzLPZ0s7RX2S%2FOntario-COVID-19-Microsite-%25E2%2580%2593-MVP-Version%3Fnode-id%3D0%253A1" allowFullScreen></iframe>
    <h2>{`Refining the visual design`}</h2>
    <p>{`Initially, when prototyping, we solely used text and links to direct users to more specific content as guided by the typical pattern the main Ontario.ca website uses. However, as organized and concise as the content was, this still created a wall of text, making it difficult to distinguish information from one another. We added visuals to divide content further.`}</p>
    <p>{`We also worked with the Ministry of Health to refine the visual design of the page to include branding they have created to inform the public about COVID-19. This resulted in a primarily green, blue, and teal colour scheme on the page. We also initially used a card approach to separate information groupings which helped enhance the visuals on the page.`}</p>
    <p>{`At this point, the site was developed, and went live due to the urgency of the pandemic and the need for the government to inform the public properly.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/ffb95/iterations.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.55555555555555%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC2UlEQVQozx2SS4tjVRSF729RcCAIgk4EEf+HcwWHOnKiNkhb6sCBoOBACx9QQmlhW1g+sFPdqaarzLu7k65U0l153dwk933POfdxbnI+8e7hZq/F2nzLIu8SLmo4T/4E2QTVJnPv4c//IbRrxMtTSJqQdsjWZyinXt1Uu22fMviXYHbbIFpQPDDWpP0b3b8Pubx7i+7tQ0zc4Ox4n6/3bvDNJx9y8MXHFO45jxrfcfjj+xwdfMB57cvKsH68xy/771L//TNzdnyTcft7Y/nTLqN2ndnDOovBBeyGvPfOWzz7zPO8+NwLvPHa6+SLU27e2ueVvY949fNPefuHr0C2ODn5lcOfD2k1z823B0c8aP1lrFKOGQ3+IJodQXwGWZ/FrEPnYYNw3SPZDCBps/GvmQUJQ9/m/uQxqA5zN0Rq/h+ziTVaLYxVSBvX9UhlSFnISqwSm1jmYErMrgDRoZBL8rRgty3RWQhxgzhyESojzzOjdck2nRtLyzm+N8dzpyTRElQPFS9IZIFKC5QUoLoUwiZVOWW5w5QCRJNcRaTptkqYqh271DaWTleESY4bSIRUZN4FSTBFpgWpUhSZJHMvsJ0xV/M1Tx0Px1ui3XvUrpecDs9pjLrmpH/B0u0YSysHL4hJhETrnF3UQiQ2q0hhBwInklXCG3fu8/JPLV46esSbtV718nA2pTce0bsameZwROA+NlaZOkSRQCnFbqtBdkjiBatQcL2JGToxpD1se0h/YjOYOtibRVWbXSHYbiErtqZCU6yM5QU2tb7H3UuP/jysDDNhI2SO1hqMBtGmVA6ZymAHbEXV1yR08P0Az/eNUDkmt40lkhWXC8GTlWST5BXRVK4ROaQacl1WhrlykQWkJZRaVlCeLj1WviRVqdm4EVrOjVXEV4TrMaVcYrIVJmmhgksib4oIZohggombyGBI6M+JgxkyGEPSwF2P8DcTEn9iQndCFg7Mf3iYZVYikX+TAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Iterations of the website",
            "title": "Iterations of the website",
            "src": "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/1cfc2/iterations.png",
            "srcSet": ["/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/3684f/iterations.png 225w", "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/fc2a6/iterations.png 450w", "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/1cfc2/iterations.png 900w", "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/21482/iterations.png 1350w", "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/d61c2/iterations.png 1800w", "/static/3ebc800c7d3e04bcda9c78c4b2f11a5e/ffb95/iterations.png 2487w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The landing page was refined and evolved over time. Click the image to zoom
  in.
    </p>
    <h4>{`Evaluate`}</h4>
    <h2>{`Continuing to learn more`}</h2>
    <p>{`After the initial website went live, I had the opportunity to conduct usability testing and take the findings to refine the visual design of the website in a team of three. We tested the live website, and future iterations that the development team was working on at the time. I helped to recruit diverse users from all over Ontario, took notes during interviews, and facilitated over 8 interviews.`}</p>
    <p>{`One important insight were people's thoughts on the way that information was organized. We had organized information based on the "group" of Ontarians one may fit into such as "students and parents", "workers", or "businesses". People commented that this was confusing and did not always capture all situations.`}</p>
    <p>{`Going forward, an iteration for this was to group information based on action or type of information rather than demographics. This allows for more flexibility and inclusivity of Ontarians.`}</p>
    <p>{`As of December 2020, the page has been updated to reflect these changes where the highest level categories are now action or categories without demographics. If needed, the subcategories cover demographics for those specific details, helping to create an information architecture that is easier to navigate and inclusive to all.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1e857daf6ece3f8526873935ab2c3f75/9b1e2/grouping.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB20lEQVQozz3R20+ScQDG8fffq6tGW7XVTXPVbOuitbausmyrtjZtU3KpbZXTdYCcKIICAV4QZ15AEYlAwBIRfQ+AvHL4fVuwdfG5+e65eyRacTCS0ElTL31nf2eNxuEWtJPD3k6AHh0yUnCeHmxrBReFtI2eEgRDFnS2oZUQ0knJQzZmIZ9cxrsyjfX9c1w2M7m4lby8Qml7HaFFBvJJC+ngAqWMjVToE17HW6oFL+WsQ8iBjxzvbwqpmHHjdlrxb3xhyfkNi2eVH34bPvcKdsc8DpeZvhrhrBbg4f1xLl8a5d7dMWZnvmKeWuLVxCQjt+6IixdGsC7OCem8WaZS0+C8hSlwyJVgFf3klKN6H092lsXITYQW5+wozMTLLR49cDI/XWduSmHmdZUb10cxma6KF09z7ER+C6mtFanUdLoNncexIuNyGfVUoaV38eWtLMhj0EwitBhRvw+/c5Pd6C578Ry5xE98jmU+f5gQnlUHSmVXSE21yF6pjtFo8WTDzjOXk4OqiqH3mQy/45r9NrRSCC0MnTh0E2CEhtoh6MpARtCLQzMkJEPNUq1WaGt/WEsHWN8OotQPMNRjfL/sLKTfQCMxOKWnRugpEfpq9L+eEqarhMS/LrSo+AuiYtsrN3zEhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Grouping of information changed",
            "title": "Grouping of information changed",
            "src": "/static/1e857daf6ece3f8526873935ab2c3f75/1cfc2/grouping.png",
            "srcSet": ["/static/1e857daf6ece3f8526873935ab2c3f75/3684f/grouping.png 225w", "/static/1e857daf6ece3f8526873935ab2c3f75/fc2a6/grouping.png 450w", "/static/1e857daf6ece3f8526873935ab2c3f75/1cfc2/grouping.png 900w", "/static/1e857daf6ece3f8526873935ab2c3f75/21482/grouping.png 1350w", "/static/1e857daf6ece3f8526873935ab2c3f75/d61c2/grouping.png 1800w", "/static/1e857daf6ece3f8526873935ab2c3f75/9b1e2/grouping.png 1880w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The grouping of information changed over time with feedback from Ontarians.
  Click the image to zoom in.
    </p>
    <p>{`The most prominent and recurring feedback we heard was that Ontarians want to see how the virus is affecting their local area. When asked how to visualize this information, they referred to data visualizations such as heatmaps or interactive charts.`}</p>
    <p>{`Often, this led to users wanting to see other information such as:`}</p>
    <Blockquote text={'"Are we flattening the curve?"'} mdxType="Blockquote" />
    <Blockquote text={'"How many hospital beds are there? Are we close to reaching capacity?"'} mdxType="Blockquote" />
    <Blockquote text={"What do the future projections look like?"} mdxType="Blockquote" />
    <p>{`Ontarians wanted to see numbers and figures from a reputable source like the Ontario government.`}</p>
    <p>{`We took our findings back to the team working on the website where they further iterated upon the design and content presented. We got enough feedback regarding the lack of data on the site and started to work on a more comprehensive data visualization page.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final designs`}</h2>
    <p>{`At the end of my first internship at the Ontario Digital Service, we had just released the second version of the homepage. However, as the pandemic has continued to evolve, the Ontario government has further iterated on the page. The most up to date version is live at `}<a href="https://covid-19.ontario.ca" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca`}</a>{`. Below is the final iteration of the home page that I worked on during my time on this project which was live between April and July 2020.`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fz3CaWo23OqcgPVO0jY5zef%2FOntario-COVID-19-Microsite-%25E2%2580%2593-Final-Version%3Fnode-id%3D1%253A8%26viewport%3D479%252C354%252C0.23687893152236938%26scaling%3Dscale-down-width" allowFullScreen></iframe>
    <br />
    <br />
    <p>{`This iteration of the landing page had many notable features:`}</p>
    <ol>
      <li parentName="ol">{`Call-to-actions regarding testing for COVID-19 including a self-assessment and method to view lab results`}</li>
      <li parentName="ol">{`Brief overview of cases in Ontario that links to a more detailed data page`}</li>
      <li parentName="ol">{`General guidelines to stop the spread of COVID-19`}</li>
      <li parentName="ol">{`A dedicated news update so Ontarians can stay informed`}</li>
      <li parentName="ol">{`Detailed information for the demographic they belong in`}</li>
    </ol>
    <p>{`Each of the links take the user to a page within the microsite with more detailed information that is presented in the typical Ontario.ca format with similar branding. This reduces the cognitive load on users and ensures they trust the information they are looking at.`}</p>
    <h2>{`Results`}</h2>
    <p>{`After the initial three day engagement, our prototype was approved by executives which led to the launch of the live website not too long afterwards for which I helped do user research and refinement of visual design for.`}</p>
    <p>{`When conducting user testing after launch, we found that our site met the initial goals we set out to accomplish, including creating an easily digestible page with important information for Ontarians. Also, the page became well viewed with the following metrics:`}</p>
    <Blockquote text="1.25 million views in the week after launch" mdxType="Blockquote" />
    <Blockquote text="450,000 views per month on average" mdxType="Blockquote" />
    <p>{`Overall, I believe our microsite was successful and serves as a great up-to-date resources for Ontarians to reference when seeking information regarding COVID-19.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`As the pandemic continues to unfold, the site needs to stay current. This includes adding information regarding testing, lockdown procedures, and vaccines when they come out.`}</p>
    <p>{`One thing we heard from Ontarians is that they seek verified data regarding the number of cases in Ontario. A next step is to publish data regarding the status of cases in Ontario in a visual format. Gratefully, I also had the opportunity to work on this aspect of the microsite. `}<Link className="link" to="/covid-data" mdxType="Link">{`Learn more about my work on Ontario's COVID-19 data visualizations.`}</Link></p>
    <h2>{`Reflecting back`}</h2>
    <p>{`These really were difficult times for many Ontarians. I spoke with individuals from across Ontario. I heard their unique stories, and learned how I can help. Each interview showed me how difficult it is to design a website meant to be accessible since the COVID-19 pandemic affects everyone. I am honoured to have had the opportunity to help design a resource that many can turn to in these times of need.`}</p>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      